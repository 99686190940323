import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RequireAuth from "./helpers/RequireAuth";
import { Navigate, Route, Routes } from "react-router-dom";
// import HomePage from "./pages/HomePage"; // Adjust the path based on your project structure
// import Home from "./component/Home/Home";
import Login from "./component/login/Login";

import Register from "./component/register/Register";
import Explore from "./component/explore/Explore";
import ShowSpace from "./component/showSpace/ShowSpace";
import WorkspacePage from "./component/showWorkspace/WorkspacePage";
import Supplier from "./component/supplier/Supplier";
import SupplierDetails from "./component/supplier/SupplierDetails";
import Community from "./component/community/Community";
import PersonalProfile from "./component/profile/PersonalProfile";
import PersonalInfo from "./component/profile/PersonalInfo";
import Wallet from "./component/profile/Wallet";
import Account from "./component/profile/Account";
import Preferences from "./component/profile/Preferences";
import LoginHome from "./component/Home/loginHome/LoginHome";
import Layout from "./component/Layout";
import ForgetPassword from "./component/login/forgetPassword/ForgetPassword";
import BookingList from "./component/booking/BookingList";
import ShowBooking from "./component/booking/ShowBooking";
import Conversations from "./component/conversations/Conversations";
import Companies from "./component/profile/Companies";
import Favorites from "./component/favorites/Favorites";
import CompanyOption from "./component/profile/CompanyOptions";
import Profile from "./component/community/company/Profile";
import { Error } from "./component/ui/Error";
import { useContext, useEffect } from "react";
import LanguageContext from "./context/LanguageProvider";
import { useTranslation } from "react-i18next";
import { setAcceptLanguageHeader } from "../axios";
import WithInternetConnection from "./component/ui/WithInternetConnection";
import StaticWelcomPage from "./component/ui/StaticWelcomPage";

function App() {
  const { language } = useContext(LanguageContext);
  const { i18n } = useTranslation();
  /*axios configuration axios.js */
  useEffect(() => {
    setAcceptLanguageHeader(i18n.language);
  }, [i18n.language]);

  return (
    <div className={`App ${language == "en" ? "font-inter" : "font-expo"}`}>
      {/* Navigation or Header can go here */}
      {/* <NavBar /> */}
      <WithInternetConnection>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/*Not Login home */}
            {/* <Route path="/" exact element={<Home />} /> */}
            <Route path="/" exact element={<StaticWelcomPage />} />

            <Route path="login" exact element={<Login />} />
            <Route path="forget-password" exact element={<ForgetPassword />} />
            <Route path="/get-started" exact element={<Register />} />
            {/*WORKEY navbar Links  */}
            <Route path="explore" exact element={<Explore />} />

            <Route element={<RequireAuth />}>
              <Route path="/profile" element={<PersonalProfile />}>
                <Route index element={<Navigate replace to="personalinfo" />} />
                <Route path="personalinfo" element={<PersonalInfo />} />
                <Route path="account" element={<Account />} />
                <Route path="preferences" element={<Preferences />} />
                <Route path="my-companies" element={<Companies />} />
                <Route path="wallet" element={<Wallet />} />
              </Route>
              <Route
                path="my-companies/create"
                element={<CompanyOption mode={"add"} />}
              />
              <Route
                path="my-companies/edit/:uuid"
                element={<CompanyOption mode={"edit"} />}
              />
              <Route
                path="my-companies/view/:uuid"
                element={<CompanyOption mode={"view"} />}
              />
              <Route
                path="my-companies/:uuid/add-service"
                exact
                element={<CompanyOption mode={"service"} />}
              />
            </Route>

            {/* <Route element={<RequireAuth />}> */}
            <Route path="explore/space/:id" element={<ShowSpace />} />
            <Route path="explore/workspace/:id" element={<WorkspacePage />} />
            {/* </Route> */}
            <Route element={<RequireAuth />}>
              <Route path="bookings" element={<BookingList />} />
              <Route path="/bookings/:id" element={<ShowBooking />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="conversations" element={<Conversations />} />
            </Route>
            <Route path="favorites" exact element={<Favorites />} />
            <Route path="suppliers" exact element={<Supplier />} />
            <Route path="suppliers/:id" exact element={<SupplierDetails />} />
            <Route path="community" exact element={<Community />} />
            <Route path="community/:uuid" exact element={<Profile />} />

            {/* Protected routes (within RequiredAuth) */}
            <Route element={<RequireAuth />}>
              {/*has Login home */}
              <Route path="login-home" exact element={<LoginHome />} />
            </Route>

            {/*Not founded Routes*/}
            {/*catch all missing Routes*/}

            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
      </WithInternetConnection>
      {/* Footer or other components can go here */}
    </div>
  );
}

export default App;
