import { useEffect } from "react";
import "./StaticWelcomPage.css";
import { useState } from "react";
const StaticWelcomPage = () => {
  const [count, setCount] = useState(0);
  const duration = 6000;
  useEffect(() => {
    // Calculate the interval for each step to reach 98 in the given duration
    const interval = duration / 98; // time per step

    const counter = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount >= 98) {
          return 0; // Reset the count to 0 when it reaches 98, triggering the counter to restart
        }

        return prevCount + 1; // Increment the count
      });
    }, interval);

    // Cleanup interval on component unmount
    return () => clearInterval(counter);
  }, [count]); // Re-run effect when count changes

  return (
    <div className=" w-full backimage relative">
      {/* <img src="/Hero.webp" alt="" className="tw-w-full tw-h-screen" /> */}
      <div className="bg-[#252C34] fixed top-0 left-0 h-[48px] w-full z-[1000] "></div>
      <div className=" responsive bg-white fixed top-[48px] left-0 h-[112px] w-full z-[1000] py-4 px-[112px] flex justify-between items-center">
        <img src="workeyLogo.png" alt="" className="h-[40px]" />
        <button className="button_soon">
          <span className=""> Coming Soon</span>
        </button>
      </div>
      <div className="hidden blur_bgImage tw-absolute xl:flex items-center justify-center ">
        <div className="fixed left-[-2%] bottom-0 iphone"></div>
        <div className="flex items-center justify-center gap-8 flex-col absolute top-[35%] right-[5%] z-10">
          <img src="/Frame 335458 (1).png" alt="" className="" />
          <div className="w-[585px] h-[40px] flex items-center justify-center gap-6">
            <div className="progress_bar_div bg-black rounded ">
              <div
                className="progress-bar "
                style={{ width: `${count}%` }}
              ></div>
            </div>
            <div className="value">{count}%</div>
          </div>
          <div className="flex items-end gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <circle opacity="0.3" cx="8.25" cy="8" r="7.5" fill="#04C946" />
              <circle cx="8.25" cy="8" r="2.5" fill="#04C946" />
            </svg>
            <div className="introd">Introducing Workey</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <circle opacity="0.3" cx="8.25" cy="8" r="7.5" fill="#04C946" />
              <circle cx="8.25" cy="8" r="2.5" fill="#04C946" />
            </svg>
          </div>
          <div className="descrip">
            Workey is Saudi Arabia's premier business hub connecting businesses
            with modern workspaces and essential services, simplifying your
            journey from start-up to success
          </div>
        </div>
      </div>
      <div className="responsive flex flex-col-reverse blur_bgImage  items-center justify-end relative hidden__ h-full">
        <div className="   iphone_mobile z-[200] lg:!h-[50%]"></div>
        <div className="responsive flex items-center justify-center gap-8 flex-col relative  top-[20%]  ">
          <img src="/Frame 335458 (1).png" alt="" className="" />
          <div className="max-w-[585px] h-[40px] flex items-center justify-center gap-6">
            <div className="progress_bar_div bg-black rounded ">
              <div
                className="progress-bar "
                style={{ width: `${count}%` }}
              ></div>
            </div>
            <div className="value">{count}%</div>
          </div>
          <div className="flex items-end gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <circle opacity="0.3" cx="8.25" cy="8" r="7.5" fill="#04C946" />
              <circle cx="8.25" cy="8" r="2.5" fill="#04C946" />
            </svg>
            <div className="introd">Introducing Workey</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <circle opacity="0.3" cx="8.25" cy="8" r="7.5" fill="#04C946" />
              <circle cx="8.25" cy="8" r="2.5" fill="#04C946" />
            </svg>
          </div>
          <div className="descrip">
            Workey is Saudi Arabia's premier business hub connecting businesses
            with modern workspaces and essential services, simplifying your
            journey from start-up to success
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticWelcomPage;
